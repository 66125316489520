/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

/*@import "https://js.arcgis.com/4.8/esri/css/main.css";*/

.mat-toolbar {
  min-height: 90px
}
.mat-fab .mat-icon {
  position: absolute;
  top: 16px;
  left: 16px;
}



app-login {
  flex: 1;
  flex-direction: column;
  display: flex !important;
}


app-scenario-selector .mat-card{
overflow-y: auto;
}

html, body, app-root {
  padding:0;
  margin: 0;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}


app-media-pathways .mat-form-field {
  width: 100%;
}

app-scenario-selector, app-home-page, app-selector-stepper{
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 15px;
}

app-selector-stepper .mat-mini-fab{
  justify-content: left;
}

app-selector-stepper .mat-stepper-vertical{
  display: inline-grid;
}

app-map-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 100vw;
}

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.basic-container {
  padding: 10px;
}

.version-info {
  font-size: 8pt;
  float: right;
}
